<template>
  <div class="container">
    <div class="content">
      <div class="title">Create A New Entry</div>
      <el-form ref="form" :model="form" :rules="rules">
        <div class="loginInputView">
          <el-form-item prop="name"
            ><el-input v-model="form.name" placeholder="Entry Title"></el-input
          ></el-form-item>
        </div>
        <div class="loginInputView" v-if="$route.query.program!=='PIE' && $route.query.program!=='POE'">
          <el-form-item>
            <el-select
              v-model="form.category"
              placeholder="Category"
              style="width: 320px"
              clearable
            >
              <el-option
                v-for="item in categories"
                :key="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="loginInputView">
          <el-form-item prop="role"
            ><el-input v-model="form.role" placeholder="Expected Role"></el-input
          ></el-form-item>
        </div>
        <div class="loginInputView">
          <el-form-item prop="account_id"
            ><el-select
              style="width: 100%"
              type="text"
              placeholder="School"
              v-model="form.account_id"
              filterable
              clearable
              remote
              :remote-method="remoteMethod"
            >
          <template v-if="accounts.length > 0">
              <el-option
                v-for="(item, index) in accounts"
                :key="index"
                :label="item.account_name"
                :value="item._id.$id"
              ></el-option>
              <div>
                  <div
                    class="empty">
                    No Records Found
                  </div>
                  <div class="report_school" @click="dialogShow">
                    <img src="@/assets/query.png" style="height: 24px; width: 24px; margin-right: 10px;">
                    <span>
                      Not Found？Report a new school to us.
                    </span>
                  </div>

                </div>
            </template>
            <template v-else >
                <div slot="empty">
                  <div
                    class="empty">
                    No Records Found
                  </div>
                  <div class="report_school" @click="dialogShow">
                    <img src="@/assets/query.png" style="height: 24px; width: 24px; margin-right: 10px;">
                    <span>
                      Not Found？Report a new school to us.
                    </span>
                  </div>

                </div>
              </template>

            </el-select
          ></el-form-item>
        </div>
        <div class="loginInputView" style="width: 100%">
          <el-form-item prop="intro"
            ><el-input
              v-model="form.intro"
              type="textarea"
              placeholder="Introduction"
              :rows="4"
            ></el-input
          ></el-form-item>
        </div>
        <div style="margin-top: 20px">Upload Cover Image</div>

        <el-upload
          action="/"
          style="margin-top: 20px"
          class="avatar-uploader"
          :show-file-list="false"
          :before-upload="beforeAvatarUpload"
        >
          <img
            v-if="form.cover_pic"
            :src="form.cover_pic"
            style="width: 150px;height: 150px; border-radius: 10px"
          />
          <img v-else-if="$route.query.program==='MR'" src="../../assets/pic.png" style="width: 150px;height: 150px" />
          <img v-else-if="$route.query.program==='PIE'" src="../../assets/piePic.png" style="width: 150px;height: 150px" />
          <img v-else-if="$route.query.program==='POE'" src="../../assets/poePic.png" style="width: 150px;height: 150px" />
        </el-upload>
      </el-form>

      <div class="confirm" @click="confirm">Create</div>
    </div>

       <el-dialog
      :visible.sync="schoolForm.dialogVisible"
      width="813px"
      height="831px"
      center
      :show-close="false"
      custom-class="dialog_class"
      close-on-click-modal
      close-on-press-escape
    >
        <div
          style="font-size: 30px;font-family: DDINAlternateRegular;font-weight: bold;color: #F65D4D;text-align: center;"
        >
          Add a New School/Institution
        </div>
        <div style="margin: 35px auto 0; width: 572px;">
          <el-input v-model="schoolForm.account_name" placeholder="school name"></el-input>
        </div>
      <div style="margin: 35px auto 0; width: 572px;">
        <el-input v-model="schoolForm.country" placeholder="country"></el-input>
      </div>
      <div style="margin: 35px auto 0; width: 572px;">
        <el-input v-model="schoolForm.province" placeholder="province"></el-input>
      </div>
        <div style="margin: 35px auto 0; width: 572px;">
          <el-input v-model="schoolForm.city" placeholder="city" ></el-input>
        </div>
      <div style="margin: 35px auto 0; width: 572px;">
        <el-input v-model="schoolForm.address" placeholder="address" ></el-input>
      </div>
      <div style="margin: 35px auto 0; width: 572px;">
        <el-input v-model="schoolForm.website" placeholder="website" slot="footer"></el-input>
      </div>
      <div
        style="width: 475px;height: 50px;background: #0E4890;border-radius: 50px;color: #FFFFFF;line-height:50px;text-align: center;margin: auto; cursor: pointer; margin-top: 120px; margin-bottom: 100px;"
        @click="subSchoolForm()">Add</div>
    </el-dialog>
    <el-dialog :visible.sync="showDialog" :close-on-click-modal="false" width="560px">
        <div class="diaTitle">Create entry</div>
        <div class="tips">{{tips}}</div>
        <div class="foot">
          <div class="cancel" @click="showDialog=false">Cancel</div>
          <div class="confirm" @click="confirmForm">Confirm</div>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import { updateEntry, getQNToken, searchSchool, reportNewSchool } from '../../api/index'
import { getProject, getUserId } from '../../utils/store'
import * as qiniu from 'qiniu-js'
import '../../assets/common/font.css'
export default {
  data () {
    return {
      form: {
        entry_id: '',
        name: '',
        category: '',
        intro: '',
        project_code: '',
        student_id: getUserId(),
        account_id: '',
        cover_pic: '',
        join_pwd_again: '',
        role: '',
        program: this.$route.query.program
      },
      schoolForm: {
        dialogVisible: false,
        account_name: '',
        country: '',
        province: '',
        city: '',
        address: '',
        website: ''
      },
      rules: {
        name: [
          {
            required: true,
            message: 'Please enter here the title of your entry',
            trigger: 'blur'
          }
        ],

        account_id: [{ required: true, message: 'School', trigger: 'change' }]
      },
      accounts: [],
      categories: [
        'Campus Magazine & Publication',
        'Campus Newspaper',
        'Yearbook',
        'Video',
        'Campus Short Film',
        'Photograph',
        'Event Promotion',
        'WeChat Official Account',
        'Video Account',
        'Comic Work',
        'Design Work',
        'Handicraft',
        'Sculpture',
        'Painting',
        'Picture Book',
        'Calligraphy',
        'Website'
      ],
      showDialog: false,
      tips: ''
    }
  },
  mounted () {
    if (this.$route.query.program === 'PIE') {
      this.form.project_code = '22-23PIE'
    }
    if (this.$route.query.program === 'POE') {
      this.form.project_code = '22-23POE'
    }
    if (this.$route.query.program === 'MR') {
      this.form.project_code = getProject()
    }
  },
  methods: {
    confirm () {
      if (this.form.project_code === '22-23POE') {
        this.tips = 'The Entry for POE 2022-2023 has not started yet. Please come back later. '
        this.showDialog = true
      } else if (this.form.project_code === getProject()) {
        this.tips = 'The Entry for MR 2022-2023 has not started yet. Please come back later. '
        this.showDialog = true
      } else {
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.tips = 'Are you sure you want to create?'
            this.showDialog = true
          }
        })
      }
    },
    confirmForm () {
      if (this.form.project_code === '22-23POE') {
        this.showDialog = false
      } else if (this.form.project_code === getProject()) {
        this.showDialog = false
      } else {
        updateEntry(this.form).then((res) => {
          if (res.data.code === 0) {
            this.$confirm(
              `Please note: the PIN of your entry is ${res.data.data.join_pwd}`,
              'Success',
              {
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
                type: 'warning'
              }
            ).then(() => {
              this.$router.push({
                path: '/application',
                query: {
                  id: res.data.application.data._id.$id
                }
              })
            })
          }
        })
      }
    },
    remoteMethod (e) {
      searchSchool(e).then((res) => {
        this.accounts = res.data
      })
    },
    subSchoolForm () {
      if (!this.schoolForm.account_name) {
        this.$message.error('Please select school name')
        return
      }
      if (!this.schoolForm.country) {
        this.$message.error('Please input country')
        return
      }
      if (!this.schoolForm.province) {
        this.$message.error('Please input province')
        return
      }
      if (!this.schoolForm.city) {
        this.$message.error('Please input city')
        return
      }
      if (!this.schoolForm.address) {
        this.$message.error('Please select address')
        return
      }
      if (!this.schoolForm.website) {
        this.$message.error('Please select website')
        return
      }

      reportNewSchool(
        this.schoolForm.account_name,
        this.schoolForm.country,
        this.schoolForm.province,
        this.schoolForm.city,
        this.schoolForm.address,
        this.schoolForm.website
      ).then((res) => {
        if (res.data.code === 0) {
          console.log(res)
          this.$message.success('Report success')
          this.schoolForm.dialogVisible = false
          this.accounts.push(res.data.msg)
          this.reForm.account_id = res.data.msg._id.$id
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    dialogShow () {
      console.log(123)
      this.schoolForm.dialogVisible = true
    },
    async getQiniuToken () {
      return new Promise((resolve) => {
        getQNToken().then((res) => {
          resolve(res)
        })
      })
    },
    async beforeAvatarUpload (file) {
      const result = await this.getQiniuToken()
      if (result.data.code === 0) {
        const token = result.data.data
        this.observable = qiniu.upload(
          file,
          new Date().getTime() + file.name,
          token
        )
        const observer = {
          complete: (res) => {
            this.form.cover_pic =
              'http://mainbucket.reachable-edu.com/' + res.key
          }
        }
        this.observable.subscribe(observer)
      } else {
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100vh;
  min-height: 900px;
  background: url('../../assets/applyBack.png');
  background-size: 110% 120%;
  background-position: 10% 10%;
  font-family: "DDINAlternateRegular";
  .content {
    width: 66%;
    margin: 0 auto;
    box-sizing: border-box;
    height: 100%;
    padding: 60px 70px;
    background: #fff;
    .title {
      color: #f65d4d;
      font-weight: bold;
      font-size: 20px;
    }
    .loginInputView {
      width: 50%;
      margin-top: 26px;
    }
    .confirm {
      margin: 20px auto;
      background: #0e4890;
      width: 300px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      border-radius: 20px;
      color: #fff;
      cursor: pointer;
    }
  }

  ::v-deep .el-input__inner {
    font-family: "DDINAlternateRegular";
    font-weight: 400;
    height: 42px;
    border: 2px solid #717171;
  }
  ::v-deep .el-input__inner:focus {
    font-family: "DDINAlternateRegular";
    font-weight: 400;
    border: 2px solid #717171;
  }
  ::v-deep .el-input__inner:hover {
    font-family: "DDINAlternateRegular";
    font-weight: 400;
    border: 2px solid #717171;
  }
  ::v-deep .el-textarea__inner {
    font-family: "DDINAlternateRegular";
    font-weight: 400;
    border: 2px solid #717171;
  }
  ::v-deep .el-textarea__inner:focus {
    font-family: "DDINAlternateRegular";
    font-weight: 400;
    border: 2px solid #717171;
  }
  ::v-deep .el-textarea__inner:hover {
    font-family: "DDINAlternateRegular";
    font-weight: 400;
    border: 2px solid #717171;
  }
  ::v-deep .el-dialog__headerbtn{
    display: none;
  }
}
 .report_school {
    font-size: 14px;
    font-family: "DDINAlternateRegular";
    font-weight: 500;
    color: #FFB414;
    border-bottom: 1px solid #F2F2F2;
    width: 90%;
    margin: auto;
    padding: 18px 0 20px;
    display: flex;
    align-items: center;
    justify-items: center;
    cursor: pointer;
    font-family: "DDINAlternateRegular";

  }
  .empty{
    font-size: 14px;font-family: "DDINAlternateRegular";font-weight: bold;color: #A4A4A4;
    border-bottom: 1px solid #F2F2F2; width: 90%; margin: auto; padding: 17px 0 15px;
      font-family: "DDINAlternateRegular";

  }
  .diaTitle{
    text-align: center;
    color: #F65D4D;
    font-size: 30px;
    font-family: "DDINAlternateRegular";
    font-weight: bold;
    text-decoration:underline
  }
  .tips{
    color: #333333;
    font-family: "DDINAlternateRegular";
    font-weight: bold;
    width: 360px;
    margin: 40px auto;
    font-size: 20px;
    line-height: 24px;
  }
  .foot{
    display: flex;
    justify-content: center;
    margin-bottom:20px;
    .cancel{
      background: #0E4890;
      border-radius: 25px;
      padding: 10px 70px;
      color: #fff;
      font-family: "DDINAlternateRegular";
      cursor: pointer;
      font-size: 18px;
    }
    .confirm{
      background: #FFB414;
      border-radius: 25px;
      padding: 10px 70px;
      color: #fff;
      font-family: "DDINAlternateRegular";
      cursor: pointer;
      margin-left: 40px;
      font-size: 18px;
    }
  }
  ::v-deep .el-dialog{
    border-radius: 15px!important;
  }

</style>
